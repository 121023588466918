module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"941219533372965"},
    },{
      plugin: require('../node_modules/gatsby-plugin-amplitude-analytics/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"c9945f5204d85386dcb4b00e5205e3d5","head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"amplitudeConfig":{"saveEvents":true,"includeUtm":true,"includeReferrer":true},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#D44718","theme_color":"#D44718","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cd905faf54c5a7e81ccd26753da02d92"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
